/**
* ChangePassword.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Etienne Daher, 2020 
* @file ChangePassword.tsx
* @author Etienne Daher
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loader from '@abstract/abstractwebcommon-client/Loader';
import { validators } from '@abstract/abstractwebcommon-shared/validators';

interface IChangePasswordProperties {
  handleSubmitPassword?: any;
  isLoading?: boolean;
}

/**
 * @interface IProfilePasswordProperties
 */
export interface IPasswordFormValues {
  oldPassword: string /** old user's password */;
  password: string /** new user's password */;
  passwordRepeat: string /** new user's password repeated */;
}

const ChangePassword = (properties: IChangePasswordProperties): JSX.Element => {
  const { t } = useTranslation();
  const { isLoading, handleSubmitPassword } = properties;
  const [isInputTypePasswordOldPassword, setIsInputTypePasswordOldPassword] =
    useState<boolean>(true);
  const [isInputTypePasswordPasswordRepeat, setIsInputTypePasswordPasswordRepeat] =
    useState<boolean>(true);
  const [isInputTypePasswordNewPassword, setIsInputTypePasswordNewPassword] =
    useState<boolean>(true);

  const getSaveBtn = (handleSubmit: any) => {
    if (isLoading) {
      return <Loader />;
    }

    return (
      <Button className="d-flex align-items-center" type="button" onClick={() => handleSubmit()}>
        <i className="bi bi-check2-circle btn-icon"></i>
        {t(`/admin/profile.profile_fieldset.edit_profile_dialog.change_password`)}
      </Button>
    );
  };

  // toggles password visibility for Old Password field
  const toggleViewPasswordOldPassword = (): void => {
    setIsInputTypePasswordOldPassword(!isInputTypePasswordOldPassword);
  };

  // toggles password visibility for Password Repeat field
  const toggleViewPasswordPasswordRepeat = (): void => {
    setIsInputTypePasswordPasswordRepeat(!isInputTypePasswordPasswordRepeat);
  };

  // toggles password visibility for New Password field
  const toggleViewPasswordNewPassword = (): void => {
    setIsInputTypePasswordNewPassword(!isInputTypePasswordNewPassword);
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          oldPassword: '',
          password: '',
          passwordRepeat: ''
        }}
        validationSchema={Yup.object({
          oldPassword: Yup.string()
            .max(20, t('/validations.max', { field: '20' }))
            .required(
              t('/validations.required', { field: t('I18N.change_password.old_password') })
            ),
          password: Yup.string()
            .min(8, t('/validations.min', { field: '8' }))
            .max(20, t('/validations.max', { field: '20' }))
            .matches(validators.PASSWORD, t('I18N.change_password.password_regex'))
            .notOneOf([Yup.ref('oldPassword')], t('I18N.change_password.same_password_on_update'))
            .required(t('/validations.required', { field: t('I18N.change_password.password') })),
          passwordRepeat: Yup.string()
            .oneOf([Yup.ref('password'), ''], t('I18N.change_password.same_password'))
            .min(2, t('/validations.min', { field: '2' }))
            .max(50, t('/validations.max', { field: '50' }))
            .notOneOf([Yup.ref('oldPassword')], t('I18N.change_password.same_password_on_update'))
            .required(
              t('/validations.required', { field: t('I18N.change_password.repeat_password') })
            )
        })}
        onSubmit={handleSubmitPassword}>
        {({ values, handleChange, handleBlur, handleSubmit, touched, errors }) => (
          <form onSubmit={handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
              <div className="p-field p-col">
                <label htmlFor="oldPassword" className="required">
                  {t('/admin/profile.profile_fieldset.datatable.columns.oldPassword')}
                </label>
                <span className="p-input-icon-right w-100">
                  <InputText
                    type={isInputTypePasswordOldPassword ? 'password' : 'text'}
                    id="oldPassword"
                    value={values.oldPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.oldPassword && errors.oldPassword ? 'p-invalid' : ''}
                  />
                  <i
                    className={isInputTypePasswordOldPassword ? 'pi pi-eye' : 'pi pi-eye-slash'}
                    style={{ cursor: 'pointer' }}
                    onClick={toggleViewPasswordOldPassword}></i>
                </span>
                {touched.oldPassword && errors.oldPassword ? (
                  <small id="oldPassword-invalid" className="p-invalid">
                    {errors.oldPassword}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
              <div className="p-field p-col">
                <label htmlFor="password" className="required">
                  {t('/admin/profile.profile_fieldset.datatable.columns.password')}
                </label>
                <span className="p-input-icon-right w-100">
                  <InputText
                    id="password"
                    type={isInputTypePasswordNewPassword ? 'password' : 'text'}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.password && errors.password ? 'p-invalid' : ''}
                  />
                  <i
                    className={isInputTypePasswordNewPassword ? 'pi pi-eye' : 'pi pi-eye-slash'}
                    style={{ cursor: 'pointer' }}
                    onClick={toggleViewPasswordNewPassword}></i>
                </span>
                {touched.password && errors.password ? (
                  <small id="password-invalid" className="p-invalid">
                    {errors.password}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
              <div className="p-field p-col">
                <label htmlFor="passwordRepeat" className="required">
                  {t('/admin/profile.profile_fieldset.datatable.columns.password_repeat')}
                </label>
                <span className="p-input-icon-right w-100">
                  <InputText
                    id="passwordRepeat"
                    type={isInputTypePasswordPasswordRepeat ? 'password' : 'text'}
                    value={values.passwordRepeat}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.passwordRepeat && errors.passwordRepeat ? 'p-invalid' : ''}
                  />
                  <i
                    className={isInputTypePasswordPasswordRepeat ? 'pi pi-eye' : 'pi pi-eye-slash'}
                    style={{ cursor: 'pointer' }}
                    onClick={toggleViewPasswordPasswordRepeat}></i>
                </span>
                {touched.passwordRepeat && errors.passwordRepeat ? (
                  <small id="passwordRepeat-invalid" className="p-invalid">
                    {errors.passwordRepeat}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="d-flex align-items-end flex-column">{getSaveBtn(handleSubmit)}</div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ChangePassword;
